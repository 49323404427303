import { PUBLIC_SENTRY_ENV } from '$env/static/public';
import {
  browserTracingIntegration,
  captureConsoleIntegration,
  handleErrorWithSentry,
  init as initSentry,
  replayIntegration
} from '@sentry/sveltekit';
import posthog from 'posthog-js';

initSentry({
  dsn: 'https://e5a5f37918b6413eb12f798e65ec870b@o1234259.ingest.sentry.io/6383507',
  enableTracing: !PUBLIC_SENTRY_ENV.includes('local'),
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    replayIntegration({
      maskAllInputs: false,
      maskAllText: false
    }),
    browserTracingIntegration({
      enableInp: true
    }),
    captureConsoleIntegration({
      levels: ['error', 'assert']
    }),
    posthog.sentryIntegration({
      organization: 'proinc',
      projectId: 6383507,
      severityAllowList: ['error', 'fatal']
    })
  ],
  environment: PUBLIC_SENTRY_ENV ?? 'unknown'
});

export const handleError = handleErrorWithSentry();
